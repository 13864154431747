<script>
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import Captcha from '$lib/Components/Forms/Captcha/Captcha.svelte';
	import Country from '$lib/Components/Forms/Country/Country.svelte';
	import SuperInput from '$lib/Components/Forms/SuperInput/SuperInput.svelte';
	import Button from '$lib/Components/Layout/Button/Button.svelte';
	import {
		handleFormSubmit,
		sendPixelConversions,
	} from '$lib/PageSections/Forms/formUtils';
	import leadSchema from '$lib/PageSections/Forms/schemas/lead.schema.js';
	import { getContext } from 'svelte';

	import toast from 'svelte-french-toast';
	import { superForm } from 'sveltekit-superforms';
	import { zodClient } from 'sveltekit-superforms/adapters';
	const { user } = getContext('sessionData');

	let loading = false;
	export let data = null;
	export let firstField;
	export let disabled = null;
	export let text = true;

	export let leadFormData;
	export const form = superForm(
		data?.forms?.leadForm ?? zodClient(leadSchema),
		{
			validators: zodClient(leadSchema),
			onSubmit({ formData }) {
				loading = true;
				handleFormSubmit(formData, $page.url);
			},
			onResult() {
				loading = false;
			},
			onUpdated({ form }) {
				if (!form.valid) {
					if (form.message?.text) {
						toast.error(form.message.text);
						$message = form.message.text;
					}
					reset?.();
				}
				if (form.valid) {
					if (form.message?.text) {
						toast.success(form.message.text);
						$message = form.message.text;
					}
					sendPixelConversions(form.message?.normalizedData);
					$user = {
						firstName: form.data.firstName,
						lastName: form.data.lastName,
						email: form.data.email,
						phone: form.data.phone,
						postalCode: form.data.postalCode,
						country: form.data.country,
					};
					if (form.message?.successUrl) {
						goto(form.message.successUrl);
					}
				}
			},
			onError({ result }) {
				toast.error(result?.error?.message);
				$message = result?.error?.message;
			},
		},
	);
	const { form: innerForm, message, enhance } = form;
	leadFormData = innerForm;

	export let buttonText = 'Contact Me About An Estimate';
	export let action = '/forms/lead';

	let reset;
	let turnstile;
	function handleInput() {
		interact = true;
	}
	let interact = false;
</script>

<form
	on:forminput={handleInput}
	on:submit={handleInput}
	name="lead"
	method="POST"
	use:enhance
	{action}
	class={['row-auto grid w-full grid-cols-2 gap-x-4', $$props.class ?? ''].join(
		' ',
	)}
>
	{#if text}
		<p class="col-span-full mb-4 p-0 text-left text-sm !text-indow-grey-dark">
			<slot name="text">
				<span>
					Fill out this form and one of our Comfort Experts will be in touch to
					prepare a specialized estimate for your space.
				</span>
			</slot>
		</p>
	{/if}
	<SuperInput
		{form}
		{disabled}
		bind:element={firstField}
		name="firstName"
		label="First Name"
		autocomplete="given-name"
		class="xs:col-span-1"
		on:focus={handleInput}
	/>
	<SuperInput
		{form}
		{disabled}
		name="lastName"
		label="Last Name"
		autocomplete="family-name"
		class="xs:col-span-1"
		required
		on:focus={handleInput}
	/>

	<SuperInput
		{form}
		{disabled}
		type="email"
		name="email"
		label="Email"
		autocomplete="email"
		required
		on:focus={handleInput}
	/>
	<SuperInput
		{form}
		{disabled}
		name="phone"
		type="tel"
		label="Phone"
		autocomplete="tel"
		on:focus={handleInput}
	/>
	<SuperInput
		{form}
		{disabled}
		name="postalCode"
		type="text"
		label="Zip / Postal Code"
		autocomplete="postal-code"
		class="xs:col-span-1"
		on:focus={handleInput}
	/>
	<Country
		{form}
		{disabled}
		name="country"
		label="Country"
		class="xs:col-span-1"
		on:focus={handleInput}
	/>

	<SuperInput
		{form}
		{disabled}
		name="notes"
		type="textarea"
		label="How can we help you? (optional)"
		on:focus{handleInput}
	/>
	{#if $message}
		<div
			class:text-green-900={$page.status == 200}
			class:text-red-900={$page.status >= 400}
			class={'col-span-full mb-4'}
		>
			{$message}
		</div>
	{/if}
	{#if interact}
		<div class="col-span-full grid">
			<Captcha bind:reset bind:turnstile />
		</div>
	{/if}
	<div class="col-span-2 grid gap-2">
		<Button class="w-full" buttonType="button" {loading} {disabled}
			>{buttonText}</Button
		>
	</div>
</form>
